import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image";
import Image from "gatsby-image";

export const ProductPageTemplate = ({
  image,
  title,
  heading,
  description,
  tableimage,
  tableheading,
  tablesblurb,
  seatingimage,
  seatingheading,
  seatingblurb,
  decorimage,
  decorheading,
  decorblurb,
  customimage,
  customheading,
  customblurb,
}) => (
    <div className="content">
      <BackgroundImage
        className="full-width-image-container margin-top-0"
        fluid={image.childImageSharp.fluid}
      >
        <div className="dark-overlay"></div>
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
            backgroundColor: "#f40",
            color: "white",
            padding: "1rem",
          }}
        >
          {title}
        </h1>
      </BackgroundImage>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
                <p>{description}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1 quad-sec">
                <div className="flex-row">
                  <div className="one-half">
                    <Image className="quad-image" fluid={tableimage.childImageSharp.fluid} alt="dark hardwood dining rooom table with white seating and white home decor and lighting" />
                    <h3 className="quad-header">
                      {tableheading}
                    </h3>
                    <p>{tablesblurb}</p>
                  </div>
                  <div className="one-half">
                    <Image className="quad-image" fluid={seatingimage.childImageSharp.fluid} alt="poolside patio lounge chair seating made of maple with built-in side table" />
                    <h3 className="quad-header">
                      {seatingheading}
                    </h3>
                    <p>{seatingblurb}</p>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="one-half">
                    <Image className="quad-image" fluid={decorimage.childImageSharp.fluid} alt="old wooden handmade clock for in home decor with accorn like accents" />
                    <h3 className="quad-header">
                      {decorheading}
                    </h3>
                    <p>{decorblurb}</p>
                  </div>
                  <div className="one-half">
                    <Image className="quad-image" fluid={customimage.childImageSharp.fluid} />
                    <h3 className="quad-header">
                      {customheading}
                    </h3>
                    <p>{customblurb}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  tableimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tableheading: PropTypes.string,
  tablesblurb: PropTypes.string,
  seatingimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  seatingheading: PropTypes.string,
  seatingblurb: PropTypes.string,
  decorimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  decorheading: PropTypes.string,
  decorblurb: PropTypes.string,
  customimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  customheading: PropTypes.string,
  customblurb: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout canonical="https://www.cannoncustomwoodworking.com/products/" title="Products of Cannon Custom Woodworking" description="Cannon Custom Woodworking in Bethel, OH builds tables, seating, home decor, dressers, china cabinets, and much more.">
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        tableimage={frontmatter.tableimage}
        tableheading={frontmatter.tableheading}
        tablesblurb={frontmatter.tablesblurb}
        seatingimage={frontmatter.seatingimage}
        seatingheading={frontmatter.seatingheading}
        seatingblurb={frontmatter.seatingblurb}
        decorimage={frontmatter.decorimage}
        decorheading={frontmatter.decorheading}
        decorblurb={frontmatter.decorblurb}
        customimage={frontmatter.customimage}
        customheading={frontmatter.customheading}
        customblurb={frontmatter.customblurb}
        description={frontmatter.description}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        description
        tableimage {
          childImageSharp {
            fluid(maxWidth: 780, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tableheading
        tablesblurb
        seatingimage {
          childImageSharp {
            fluid(maxWidth: 780, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seatingheading
        seatingblurb
        decorimage {
          childImageSharp {
            fluid(maxWidth: 780, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        decorheading
        decorblurb
        customimage {
          childImageSharp {
            fluid(maxWidth: 780, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        customheading
        customblurb
      }
    }
  }
`;
